//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ElSlider from '@/components/common/Slider'

export default {
  name: 'FeedbackSectionVue',
  components: {
    ElSlider,
  },
  data() {
    return {
      options: {
        slidesPerView: 2,
        spacing: 120,
        loop: true,
        duration: 1000,
        breakpoints: {
          '(max-width: 1280px)': {
            sliderView: 2,
            spacing: 60,
            loop: true,
          },
          '(max-width: 640px)': {
            slidesPerView: 1,
            loop: true,
            dragStart: () => {
              this.setPause(true)
            },
            dragEnd: () => {
              this.setPause(false)
            },
          },
        },
      },
      current: 1,
      sliderSize: 0,
      pause: false,
      interval: null,
      hideArrow: false,
    }
  },
  computed: {
    reviews() {
      return this.$getBullets('reviews') || []
    },
    isReviewsHidden() {
      return this.$getTextSetting('isReviewsHidden')
    },
  },
  mounted() {
    if (this.reviews.length) {
      window.addEventListener('resize', this.resize, true)
      setTimeout(() => {
        this.resize()
      }, 500)
    }
  },
  beforeDestroy() {
    if (!this.isReviewsHidden && this.$refs.slider) {
      this.$refs.slider.slider.destroy()
      this.resetInterval()
    }
  },
  methods: {
    resize() {
      if (!this.isReviewsHidden && this.$refs.slider !== undefined) {
        this.hideArrow =
          this.$refs.slider.slider.details().size >
          this.$refs.slider.slider.details().slidesPerView
        if (window.innerWidth < 640) {
          this.sliderSize = this.$refs.slider.slider.details().size
          this.options.slideChanged = (s) => {
            this.current = s.details().relativeSlide
          }
          this.$refs.slider.slider.refresh(this.options)
          this.setInterval()
        } else {
          this.resetInterval()
        }
      }
    },
    setPause(active) {
      this.pause = active
      this.setInterval()
    },
    resetInterval() {
      clearInterval(this.interval)
    },
    setInterval() {
      if (!this.isReviewsHidden && this.$refs.slider) {
        this.resetInterval()
        this.interval = setInterval(() => {
          if (!this.pause) {
            this.$refs.slider.slider.next()
          }
        }, 3000)
      }
    },
    feedbackPhoto(imageFile) {
      if (imageFile) {
        return imageFile.url
      }
      return require('~/assets/img/partner/main/feedback-section/reviewer1.png')
    },
    getDirection() {
      return this.$direction()
    },
  },
}
